


























import { Component, Vue, Watch } from 'vue-property-decorator'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import ModalDanger from '../../components/Modals/ModalDanger/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { PeriodRule } from '@/domain/entities/Payroll'

@Component({
  components: {
    Tabs,
    ModalDanger,
  },
})
export default class EditManageDataMaster extends Vue {
  controller = controller
  modalConfirmVisible = false
  currentTab = 0
  selectedTab = 0

  currentPeriod = ''

  itemTabs = [
    { label: 'Basic Fee', value: 0 },
    { label: 'Bonus Mekanisme', value: 1 },
    { label: 'Penarikan Dana', value: 2 },
    { label: 'Update History', value: 3 },
  ]

  created(): void {
    if (this.$route.name !== this.getTabRoute(0)) {
      this.$router.push({ name: this.getTabRoute(0) })
    }
    this.getCurrentPeriod()
  }

  private getCurrentPeriod(): void {
    const res = controller.dataPayrollList.find(
      item => item.id === Number(this.$route.params.periodId)
    )
    this.currentPeriod = `${this.formatDateToID(
      'DD MMMM',
      res?.startAt
    )} - ${this.formatDateToID('DD MMMM YYYY', res?.endAt)}`
  }

  private formatDateToID(format: string, date?: string): string {
    if (!date) return ''
    return dayjs(date)
      .locale(dayjsID)
      .format(format)
  }

  private onTabsClick(tab: number): void {
    this.selectedTab = tab

    if (controller.isChanged) {
      this.modalConfirmVisible = true
    } else {
      this.onChangeTab()
    }
  }

  private onChangeTab(): void {
    controller.setIsChanged(false)
    this.modalConfirmVisible = false
    this.currentTab = this.selectedTab

    this.itemTabs.filter(item => {
      if (item.value === this.selectedTab)
        this.$router.push({ name: this.getTabRoute(item.value) })
    })
  }

  private getTabRoute(key: number): string {
    if (key === 0) {
      return 'BasicFeeEdit'
    }
    if (key === 1) {
      return 'BonusMechanismEdit'
    }
    if (key === 2) {
      return 'FundsWithdrawalEdit'
    } else {
      return 'UpdateHistoryEdit'
    }
  }

  @Watch('controller.dataPeriodRule', { deep: true })
  onDataPeriodRuleChanged(data: PeriodRule): void {
    if (data.status === 'inactive') {
      this.$notify({
        title: 'Access Denied',
        text: 'Cannot Edit Inactive Period',
        type: 'error',
        duration: 4000,
      })

      this.$router
        .replace({
          name: 'ManageDataMasterDetail',
          params: {
            page: this.$route.params.page,
            periodId: this.$route.params.periodId,
          },
        })
        .then(() => {
          this.controller.setLoadingDetail(false)
        })
    } else {
      this.controller.setLoadingDetail(false)
    }
  }
}
